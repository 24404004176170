/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/codemirror@5.37.0/lib/codemirror.js
 * - /npm/codemirror@5.37.0/mode/coffeescript/coffeescript.js
 * - /npm/codemirror@5.37.0/addon/lint/coffeescript-lint.js
 * - /npm/codemirror@5.37.0/mode/javascript/javascript.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
